<div class="cruises-listMainContainer">

    <div #tableHeader class="tableToolbar">
        <h3>Zaplanowane rejsy ({{ cruisesLength }})</h3>
        <button mat-raised-button (click)="deleteSelectedCruises()" style="margin-right: 1em;">Usuń zaznaczone</button>
        <button mat-raised-button (click)="refreshCruises()">Odśwież</button>
    </div>

    <div class="table-container">

        <div *ngIf="isLoadingResults" class="spinner-container">
            <mat-spinner></mat-spinner>
        </div>

        <div class="inner-table-container">

            <table mat-table [dataSource]="cruises" matSort>

                <ng-container matColumnDef="select">
                    <th mat-header-cell *matHeaderCellDef>
                        <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()">
                        </mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)">
                        </mat-checkbox>
                    </td>
                </ng-container>

                <ng-container matColumnDef="ship">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Statek</th>
                    <td mat-cell *matCellDef="let element"> {{element.shipName}} </td>
                </ng-container>

                <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Data</th>
                    <td mat-cell *matCellDef="let element"> {{element.date | date:"dd.MM.yyyy, HH:mm" }} </td>
                </ng-container>

                <ng-container matColumnDef="bus">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Autobus</th>
                    <td mat-cell *matCellDef="let element" style="overflow: visible;">
                        <mat-checkbox [checked]="element.bus" (change)="toggleBus($event, element)"></mat-checkbox>
                    </td>
                </ng-container>

                <ng-container matColumnDef="passengers">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Ilość pasażerów</th>
                    <td mat-cell *matCellDef="let element"> {{element.passengers}} </td>
                </ng-container>

                <ng-container matColumnDef="delete">
                    <th mat-header-cell *matHeaderCellDef>Usuwanie</th>
                    <td mat-cell *matCellDef="let element">
                        <button mat-icon-button (click)="deleteCruise(element)" aria-label="Delete">
                            <mat-icon aria-label="">delete</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            </table>

        </div>

    </div>

    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]">
    </mat-paginator>

</div>