import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { CruiseService } from '../service/cruise.service';
import { Cruise } from '../classes/Cruise';
import { MatSnackBar } from '@angular/material/snack-bar';
import { momentDateValidator } from '../validators/momentDateValidator.directive';
import { ComponentConnectorService } from '../service/component-connector.service';
import { EventOnBus } from '../classes/EventOnBus';
import { Ship } from '../classes/Ship';
import * as _moment from 'moment';

const Moment = _moment;

@Component({
  selector: 'app-add-new-cruise',
  templateUrl: './add-new-cruise.component.html',
  styleUrls: ['./add-new-cruise.component.css']
})
export class AddNewCruiseComponent implements OnInit {

  	public form = new FormGroup({
		date : new FormControl(null, [Validators.required, momentDateValidator]),
		hours : new FormControl('12', [Validators.min(0), Validators.max(23), Validators.required]),
		minutes : new FormControl('00', [Validators.min(0), Validators.max(59), Validators.required]),
		bus: new FormControl()
	});
	
	oErrorMapping = {
		date: { required: "Wprowadź wartość", wrongDate: "Błędna data"},
		hours: { max : "Dozwolone wartości 0-23", minlength: "Wprowadź wartość"},
		minutes: {max : "Dozwolone wartości 0-59", minLength: "Wprowadź wartość"}
    };
    
	private selectedShip : Ship;
	// private SpeechRecognition = SpeechRecognition || webkitSpeechRecognition;
	// private SpeechGrammarList = SpeechGrammarList || webkitSpeechGrammarList;
	// private SpeechRecognitionEvent = SpeechRecognitionEvent || webkitSpeechRecognitionEvent;


	private SpeechRecognition = Window['webkitSpeechRecognition'];
	private SpeechGrammarList = Window['webkitSpeechGrammarList'];
    private SpeechRecognitionEvent = Window['webkitSpeechRecognitionEvent'];
    private recognition;
    private speechRecognitionList;
	
	constructor(private cruiseService: CruiseService, 
				public snackBar: MatSnackBar,
				private componentConnectorService: ComponentConnectorService) { }

	ngOnInit() {

        this.componentConnectorService.selectedShip.subscribe({
            next: function(oShip : Ship) {
                this.selectedShip = oShip;
            }.bind(this)
		});

        try {

            this.recognition = new this.SpeechRecognition();
            this.speechRecognitionList = new this.SpeechGrammarList();

            this.recognition.onresult = this.onSpeechRecognitionResult.bind(this);

            this.recognition.onend = function(event) {

                this.recognition.start();

            }.bind(this);

            let sGrammar = "#JSGF V1.0; grammar action;  <action> = /2/ Dodaj | /1/ Usuń; public <command> = <action> [rejs];";
            
            this.speechRecognitionList.addFromString(sGrammar, 1);

            this.recognition.grammars = this.speechRecognitionList;

            this.recognition.lang = 'pl';
            this.recognition.start();
        
        } catch(oError) {
            //do nothing
        }

	}

	onSpeechRecognitionResult(oEvent) {

		let last = oEvent.results.length - 1;
		let word = oEvent.results[last][0].transcript;

		this.determineResult(word);

	}

	private oCurrentStage = undefined;
	private oCurrentStageCounter = 0;
	private oStagesDefinition = {
		"dodaj": {
			"date": null
		},
		"usuń" : {
			"date": null
		},
		"anuluj": null
	};

	determineResult(sSentence : string) {

		let bFound = false;
		let oTempStage;

		sSentence = sSentence.toLowerCase();

		if(this.oCurrentStage === undefined) {
			this.oCurrentStage = JSON.parse(JSON.stringify(this.oStagesDefinition));
		}

		if(this.oCurrentStage === null) {

		}

		oTempStage = JSON.parse(JSON.stringify(this.oCurrentStage));

		if(this.oCurrentStageCounter > 0) {
			let i = 0;
			do {

				for(var sKey2 in oTempStage) {
					oTempStage = oTempStage[sKey2];
				}

				i++;
			} while(i < this.oCurrentStageCounter);
		}

		for(var sKey in this.oCurrentStage) {
			if(sSentence.indexOf(sKey) < 0) {
				delete this.oCurrentStage[sKey];
			} else {
				bFound = true;
				this.oCurrentStageCounter++;
			}
		}

		if(!bFound) {
			this.oCurrentStage = undefined;
			return;
		}

		if(this.oCurrentStage === null) {
			if(sKey === "date") {

			}
		}

	}

	onMinutesChange(oValue) {	

		if(oValue.toString().length > 2) {
			this.form.get("minutes").setValue(oValue.toString().slice(-2));
		} else if(oValue.toString().length === 1) {
			this.form.get("minutes").setValue("0" + oValue);
		} else if(oValue.toString().length === 0) {
			this.form.get("minutes").setValue("00");
		}

		if(parseInt(this.form.get("minutes").value) > 59) {
			this.form.get("minutes").setValue("59");
		} else if(parseInt(this.form.get("minutes").value) < 0) {
			this.form.get("minutes").setValue("00");
		}

	}

	onHoursChange(oValue) {	

		if(oValue.toString().length > 2) {
			this.form.get("hours").setValue(oValue.toString().slice(-2));
		} else if(oValue.toString().length === 1) {
			this.form.get("hours").setValue("0" + oValue);
		} else if(oValue.toString().length === 0) {
			this.form.get("hours").setValue("00");
		}

		if(parseInt(this.form.get("hours").value) > 23) {
			this.form.get("hours").setValue("23");
		} else if(parseInt(this.form.get("hours").value) < 0) {
			this.form.get("hours").setValue("00");
		}

	}

	getErrorMessage(sFormControl) {

		let errors = this.form.get(sFormControl).errors;

		if(errors) {
			return this.oErrorMapping[sFormControl][Object.keys(errors)[0]]
		}
		return "Błędna wartość";
	}

	onAddNewCruise() : boolean {

		if(!this.validateForm()) {
			return false;
        }

		let oMomentDate = Moment(this.form.get("date").value);
		let iHours = this.form.get("hours").value;
		let iMinutes = this.form.get("minutes").value;
        let bus = this.form.get("bus").value === true ? true : false;

		oMomentDate.hours(iHours);
		oMomentDate.minutes(iMinutes);

		let oNewCruise : Cruise = {
                id: null, 
                shipId: this.selectedShip.id,
                shipName: this.selectedShip.name,
				date: oMomentDate.utc().toDate(),
				passengers: 0,
				bus: bus
			};

		this.cruiseService.addCruise(oNewCruise).subscribe({
			next: this.checkResponseAndEmit.bind(this),
			error: this.checkResponseAndEmit.bind(this)
		});

		return true;

	}

	checkResponseAndEmit(oResponse : any) {

		if(oResponse && oResponse.status === 401) {

			this.snackBar.open("Sesja wygasła, zaloguj się ponownie", null, {
				duration: 3000
			});

			this.logout();

		} else if(oResponse === true) {

			this.componentConnectorService.emit(new EventOnBus("newCruise"));
			this.snackBar.open("Dodano rejs", null, {
				duration: 2000
			});

		} else if(oResponse === "duplicated") {

			this.snackBar.open("Rejs o podanej dacie już istnieje", null, {
				duration: 4000
			});

		}

	}

	validateForm() {

		for( let i in this.form.controls) {

			this.form.controls[i].markAsTouched();

		}

		this.form.updateValueAndValidity({
			onlySelf: false,
			emitEvent: true
		});

		if(this.form.invalid) {

			this.snackBar.open("Popraw błędy", null, {
				duration: 2000
			});

			return false;
		} else {
			return true;
		}

	}

	momentDateToMySqlDate(oMoment) : string {

		if(oMoment) {
			let sYear : string = oMoment.utc().year();
			let sMonth : string = (oMoment.utc().month() + 1).toString();
			if(sMonth.length === 1) {
				sMonth = "0" + sMonth;
			}
			let sDay : string = oMoment.utc().date().toString();
			if(sDay.length === 1) {
				sDay = "0" + sDay;
			}
			let sHours : string = oMoment.utc().hours().toString();
			if(sHours.length === 1) {
				sHours = "0" + sHours;
			}
			let sMinutes : string = oMoment.utc().minutes().toString();
			if(sMinutes.length === 1) {
				sMinutes = "0" + sMinutes;
			}
			let sSeconds : string = oMoment.utc().seconds().toString();
			if(sSeconds.length === 1) {
				sSeconds = "0" + sSeconds;
			}

			oMoment['_isUTC'] = false;

			return sYear + "-" + sMonth + "-" + sDay + " " + sHours + ":" + sMinutes + ":" + sSeconds;
		} else {
			return "";
		}

	}

	onDateChange() {
		// console.log(this.form.get("date").value);
	}

	logout() {

		this.componentConnectorService.emit(new EventOnBus("logout"));

	}

}
