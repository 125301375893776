<div>
  <h3>Opis na stronie</h3>
  <form [formGroup]="form"
      class="form">
      <mat-form-field>
          <textarea
              matInput
              matTextareaAutosize
              cdkTextareaAutosize
              formControlName="text"
              placeholder="Wypełnij lub pozostaw puste"></textarea>
      </mat-form-field>
  </form>
  <div>
      <button mat-raised-button (click)="onChangeDescription()">Zmień opis na stronie</button>
  </div>
</div>