import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DescriptionService } from '../service/description.service';
import { Text } from '../classes/Text';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ComponentConnectorService } from '../service/component-connector.service';
import { Ship } from '../classes/Ship';
import { EventOnBus } from '../classes/EventOnBus';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'app-change-information-text',
  templateUrl: './change-information-text.component.html',
  styleUrls: ['./change-information-text.component.css']
})
export class ChangeInformationTextComponent implements OnInit {

  public form = new FormGroup({
		text : new FormControl("")
    });
    
    private selectedShip : Ship;
    private oText : Text;

	constructor(private descriptionService : DescriptionService,
                public snackBar: MatSnackBar,
                private componentConnectorService: ComponentConnectorService) { }

	ngOnInit() {

        combineLatest([this.componentConnectorService.sTokenObservable, 
            this.componentConnectorService.selectedShipObservable]).subscribe({
                next: function(aParams : any[]) {

                    this.selectedShip = aParams[1];

                    this.getDescription();

                }.bind(this)
            });

	}

	onChangeDescription() {
        let sValue = this.form.get("text").value;
        
        this.oText.text = sValue;

        this.descriptionService.changeDescription(this.oText).
            subscribe({
                next: this.changeSuccess.bind(this),
                error: this.handleError.bind(this)
            });
    }
    
    handleError(oResponse : any) {

        if(oResponse && oResponse.status === 401) {

			this.snackBar.open("Sesja wygasła, zaloguj się ponownie", null, {
				duration: 3000
			});

			this.logout();

		}

    }

    logout() {

		this.componentConnectorService.emit(new EventOnBus("logout"));

	}

	getDescription() {
        this.descriptionService.getDescription(String(this.selectedShip.id)).
            subscribe(function(aText : Text[]) {
                this.oText = aText[0];
                this.form.get("text").setValue(this.oText.text);
            }.bind(this));

	}

	changeSuccess(bSuccess) {
		if(bSuccess) {
			this.snackBar.open("Opis został zmieniony", null, {
				duration: 2000
			});
		} else {
			this.snackBar.open("Opis nie został zmieniony", null, {
				duration: 2000
			});
		}
		this.getDescription();
		
	}

}
