import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Text } from '../classes/Text';
import { PersistenceHelperService } from '../util/persistence-helper.service';
import { ComponentConnectorService } from './component-connector.service';

@Injectable({
  providedIn: 'root'
})
export class DescriptionService {

  // localhost
    // private sUrlPrefix = "/php/statekopole/";
    //productive
    private sUrlPrefix = "app/php/";

    private changeDescUrl = this.sUrlPrefix + "changeDescription.php";
    private getDescUrl = this.sUrlPrefix + "getDescription.php";

    constructor(private http: HttpClient,
        private PersistenceHelper: PersistenceHelperService,
        private componentConnectorService : ComponentConnectorService) { }

    changeDescription(oText: Text) : Observable<Text> {

        let sToken = this.componentConnectorService.sToken.getValue();

        var oHeaders = new HttpHeaders();

        oHeaders = oHeaders.append("x-session-token", sToken);

		return this.http.post<Text>(this.changeDescUrl, oText, {
            headers: oHeaders
        });


    }

    getDescription(sShipId : string) {
        return this.http.get<Text>(this.getDescUrl, {
            params: {
                shipId: sShipId,
                textName: "MAIN_SITE_DESC"
            }
        });
    }

}
