import { Component, OnInit } from '@angular/core';
import { ComponentConnectorService } from './service/component-connector.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EventOnBus } from './classes/EventOnBus';
import { MatDialog } from '@angular/material/dialog';
import { Ship } from './classes/Ship';
import { ShipsDialogComponent } from './component/dialog/ships-dialog/ships-dialog.component';
import { PersistenceHelperService } from './util/persistence-helper.service';
import { ShipService } from './service/ship.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  
  	private title = 'app';
    public logged = false;
    public bShowApp = false;
    private selectedShip : Ship;
    private aShips : Ship[];

    constructor(private componentConnectorService: ComponentConnectorService,
        public snackBar: MatSnackBar,
        public dialog: MatDialog,
        private PersistenceHelper: PersistenceHelperService,
        private shipService : ShipService) {}

    ngOnInit() {

        this.componentConnectorService.selectedShipObservable.subscribe({
            next: this.onShipSelected.bind(this)
        });

        // load ships from back-end
        this.getShips();

		this.componentConnectorService.$communicationBus.subscribe(function(oEvent : EventOnBus) {

            if(oEvent.getId() === "login") {
                this.handleLogin();
            }
            
            if(oEvent.getId() === "logout") {
                this.handleLogout();
            }

            if(oEvent.getId() === "selectShip") {
                this.showShipsDialog();
            }
      
		}.bind(this));

	}

    handleLogin() {

        this.logged = true;

        this.componentConnectorService.aShipsObservable.subscribe({
            next: this.shipsLoaded.bind(this)
        });

        this.snackBar.open("Zalogowano", null, {
          	duration: 1000
        });

    }

    shipsLoaded(aShips : Ship[]) {

        var oParams = this.PersistenceHelper.getQueryObject();

        if(oParams.ship != null) {

            let bFound = false;

            for(var i = 0; i < aShips.length; i++) {
                if(aShips[i].id === parseInt(oParams.ship)) {
                    this.componentConnectorService.selectedShip.
                        next(aShips[i]);

                    bFound = true;
                    break;
                }
            }

            if(bFound === false) {
                this.showShipsDialog();
            }

        } else {
            this.showShipsDialog();
        }

    }

    handleLogout() {

        var oParams = this.PersistenceHelper.getQueryObject();

        if(oParams.token) {
            delete oParams.token;
        }

        var sUrl = this.PersistenceHelper.createUrl(oParams);

		window.history.pushState({}, "", sUrl);

        this.logged = false;
        this.bShowApp = false;

    }

    showShipsDialog() {

        const oDialog = this.dialog.open(ShipsDialogComponent,
            {	width: '250px',
                disableClose: true,
				data: this.aShips } );

    }
    
    onShipSelected(oShip : Ship) {

        if(oShip == null) {
            return false;
        }

        this.selectedShip = oShip;

        this.showApp(oShip);

        return true;

    }
	
	showApp(oShip : Ship) {

        if(oShip == null) {
            return false;
        }

        this.bShowApp = true;

        var oParams = this.PersistenceHelper.getQueryObject();

        oParams.ship = oShip.id;

        var sUrl = this.PersistenceHelper.createUrl(oParams);

        window.history.pushState({}, "", sUrl);

    }
    
    getShips() : void {

		this.shipService.getShips().subscribe({
			next: function(aShips : Ship[]) { 

                this.aShips = aShips;
                this.componentConnectorService.aShips.next(aShips);

            }.bind(this)
		});
	}

}
