<div class="container-fluid" style="height:600px;" *ngIf="logged == false">
    <div class="row align-items-center" style="height:100%;">
        <div class="col-12">
            <div class="container-fluid">
                <div class="row justify-content-center align-items-center">
                    <div class="col-auto">
                        <app-login-page id="loginPage"></app-login-page>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="appContainer" *ngIf="bShowApp == true">

    <app-header-ship></app-header-ship>
    <div class="paddingTiny" style="display: flex;flex-wrap: wrap;">
        <app-add-new-cruise></app-add-new-cruise>
        <div id="app-desc-break"></div>
        <app-change-information-text></app-change-information-text>
    </div>
    <p style="clear: both;"></p>
    <div class="paddingTiny">
        <app-cruises-list></app-cruises-list>
    </div>
</div>