<div>
	<h3>Dodaj rejs</h3>
	<form 
		class="form" 
		[formGroup]="form">

		<mat-form-field class="date">
			<input 
				matInput [matDatepicker]="dp"
				(dateChange) = "onDateChange()"
				formControlName="date" 
				placeholder="Data rejsu">
			<mat-datepicker-toggle 
				matSuffix [for]="dp"
			></mat-datepicker-toggle>
			<mat-datepicker #dp></mat-datepicker>
			<mat-error *ngIf="form.controls.date.invalid">{{getErrorMessage('date')}}</mat-error>
		</mat-form-field>
		<div class="timeWrapper">
			<mat-form-field class="hours">
				<input 
					matInput 
					type="number" 
					formControlName="hours"
					placeholder="Godzina"
					(change)="onHoursChange($event.target.value)">
				<mat-error *ngIf="form.controls.hours.invalid">{{getErrorMessage('hours')}}</mat-error>
			</mat-form-field>
			<mat-form-field class="hours">
				<input 
					matInput type="number" 
					formControlName="minutes"
					(change)="onMinutesChange($event.target.value)">
				<mat-error *ngIf="form.controls.minutes.invalid">{{getErrorMessage('minutes')}}</mat-error>
			</mat-form-field>
		</div>
		<mat-checkbox class="busCheckBox" formControlName="bus">Autobus</mat-checkbox>
	</form>

	<button mat-raised-button (click)="onAddNewCruise()">Dodaj</button>
</div>
