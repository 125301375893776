export class EventOnBus {

    private id: string;
    private parameters: Object;

    constructor(sId: string, oParams?: Object) {

        this.id = sId;
        this.parameters = oParams;

    }

    getId() : string {
        return this.id;
    }

    getParameters() : Object {
        return this.parameters;
    }

    getParameter(sParamName : string) : Object {

        return this.parameters && this.parameters[sParamName] ? this.parameters[sParamName] : undefined;

    }

};