import { Component, OnInit, ViewChild } from '@angular/core';
// import { MatTableDataSource } from '@angular/material';
import { CruiseService } from '../service/cruise.service';
import { Cruise } from '../classes/Cruise';
import { ComponentConnectorService } from '../service/component-connector.service';
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
// import { DatePipe } from '@angular/common';
import { combineLatest } from 'rxjs';
import { Ship } from '../classes/Ship';
import { EventOnBus } from '../classes/EventOnBus';
import * as _moment from 'moment';

const Moment = _moment;

@Component({
  selector: 'app-cruises-list',
  templateUrl: './cruises-list.component.html',
  styleUrls: ['./cruises-list.component.css']
})
export class CruisesListComponent implements OnInit {

    @ViewChild(MatPaginator) dataPaginator: MatPaginator;
    @ViewChild(MatSort) dataSort: MatSort;

  	public displayedColumns = ['select', 'ship', 'date', 'bus', 'passengers', 'delete'];
    public cruises : MatTableDataSource<Cruise> = new MatTableDataSource([]);;
    public cruisesLength = 0;
    private selection = new SelectionModel<Cruise>(true, []);
    private selectedShip: Ship;
    public isLoadingResults = false;

	constructor(private cruiseService: CruiseService,
				private componentConnectorService: ComponentConnectorService,
                public snackBar: MatSnackBar,
                private matPaginatorIntl : MatPaginatorIntl) { }

	ngOnInit() {

        this.matPaginatorIntl.itemsPerPageLabel = "rejsów na stronę";
        this.matPaginatorIntl.firstPageLabel = "Pierwsza strona";
        this.matPaginatorIntl.lastPageLabel = "Ostatnia strona";
        this.matPaginatorIntl.nextPageLabel = "Następna strona";
        this.matPaginatorIntl.previousPageLabel = "Poprzednia strona";
        this.matPaginatorIntl.getRangeLabel = 
            function(page: number, pageSize: number, length: number) { 
                if (length == 0 || pageSize == 0) { 
                    return `0 z ${length}`; 
                } 
                length = Math.max(length, 0); 
                const startIndex = page * pageSize; 
                const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : 
                    startIndex + pageSize; 
                
                return `${startIndex + 1} - ${endIndex} z ${length}`; 
            };

        combineLatest([this.componentConnectorService.sTokenObservable, 
            this.componentConnectorService.selectedShipObservable]).subscribe({
                next: function(aParams : any[]) {

                    this.selectedShip = aParams[1];

                    this.getCruises();

                }.bind(this)
            });

		this.componentConnectorService.$communicationBus.subscribe(function(oEvent : EventOnBus) {
			if(oEvent.getId() === "newCruise") {
				this.getCruises();
			}
		}.bind(this));
    }
    
    ngAfterViewInit() {

        this.cruises.paginator = this.dataPaginator;
        this.cruises.sort = this.dataSort;

    }

	getCruises(filter? : string) : void {

        this.isLoadingResults = true;

		this.cruiseService.getCruises({shipId: String(this.selectedShip.id)}).subscribe({
			next: function(cruises) { 
                this.convertDataFromPHP(cruises);
                this.cruisesLength = cruises.length;
            }.bind(this),
            error: this.onBackEndError.bind(this),
            complete: function() {
                this.isLoadingResults = false;
            }.bind(this)
        });
        
	}

	convertDataFromPHP(cruises : Cruise[]) {

		for(var i in cruises) {
			var oMoment = Moment.utc(cruises[i].date);
			oMoment['_isUTC'] = false;
            cruises[i].date = oMoment.toDate();
        }
        
		this.cruises.data = cruises;

	}

	isAllSelected() {
		let numSelected = this.selection.selected.length;
		let numRows = this.cruises.data.length;
		return numSelected === numRows;
	}

	masterToggle() {
		this.isAllSelected() ?
			this.selection.clear() :
			this.cruises.data.forEach(row => this.selection.select(row));
	}

	deleteCruise(cruise : Cruise) {

		this.deleteCruises([cruise]);

	}

	deleteCruises(cruises : Cruise[]) {
		this.cruiseService.deleteCruises(cruises).subscribe(() => {
			this.snackBar.open("Usunięto rejsy", null, {
				duration: 2000
			});
			this.getCruises();

			this.selection.clear();
		});
	}

	deleteSelectedCruises() {
		let aCruises = this.selection.selected;

		if(aCruises.length > 0) {
			this.deleteCruises(aCruises);
		} else {
			this.snackBar.open("Brak zaznaczonych rejsów", null, {
				duration: 2000
			});
		}
	}

	toggleBus(oEvent, oCruise : Cruise) {

		oCruise.bus = oEvent.checked;
		this.cruiseService.updateCruise(oCruise).subscribe(() => {
			this.snackBar.open("Rejs zaktualizowany", null, {
				duration: 2000
			});
		});
		
	}

	onBackEndError(oData) {

        console.log("Fatal error");

		// let sUrl = [location.protocol, '//', location.host, location.pathname].join('');
		// window.location.href = sUrl;

    }
    
    refreshCruises() {

        this.getCruises();

    }

}
