import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PersistenceHelperService } from '../util/persistence-helper.service';
import { Ship } from '../classes/Ship';

@Injectable({
    providedIn: 'root'
})
export class ShipService {

    private sUrlPrefix = "app/php/";

    private getShipsUrl = this.sUrlPrefix + "getShips.php";

    constructor(private http: HttpClient, private PersistenceHelper: PersistenceHelperService) { }

    getShips() : Observable<Ship[]> {

        return this.http.get<Ship[]>(this.getShipsUrl);

    }
    
}
