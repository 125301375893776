import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AddNewCruiseComponent } from './add-new-cruise/add-new-cruise.component';
import { ChangeInformationTextComponent } from './change-information-text/change-information-text.component';
import { CruisesListComponent } from './cruises-list/cruises-list.component';
import { LoginPageComponent } from './login-page/login-page.component';

import { FormsModule, ReactiveFormsModule} from '@angular/forms';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

import { CruiseService } from './service/cruise.service';
import { ComponentConnectorService } from './service/component-connector.service';
import { DescriptionService } from './service/description.service';
import { LoginService } from './service/login.service';
import { ShipService } from './service/ship.service';
import { ShipsDialogComponent } from './component/dialog/ships-dialog/ships-dialog.component';
import { HeaderShipComponent } from './component/header-ship/header-ship.component';

@NgModule({
  declarations: [
    AppComponent,
    AddNewCruiseComponent,
    ChangeInformationTextComponent,
    CruisesListComponent,
    LoginPageComponent,
    ShipsDialogComponent,
    HeaderShipComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatInputModule,
    MatMomentDateModule,
    MatButtonModule,
    MatTableModule,
    MatSnackBarModule,
    MatCheckboxModule,
    MatIconModule,
    MatSelectModule,
    MatToolbarModule,
    MatDialogModule,
    MatListModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressSpinnerModule
  ],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'pl-PL'},
		{provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
    CruiseService,
    ComponentConnectorService,
    DescriptionService,
    LoginService,
    ShipService,
    MatPaginatorIntl
  ],
  bootstrap: [AppComponent],
  entryComponents: [ShipsDialogComponent]
})
export class AppModule { }
