import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject} from 'rxjs';
import { filter } from 'rxjs/operators'
import { EventOnBus } from '../classes/EventOnBus';
import { Ship } from '../classes/Ship';

@Injectable({
  providedIn: 'root'
})
export class ComponentConnectorService {

    private fnFilterNotNull = filter(function(oObject : any){
        return oObject != null;
    });

    public objectToEmit = new ReplaySubject<EventOnBus>(5);
    public $communicationBus = this.objectToEmit.asObservable();
    public selectedShip = new BehaviorSubject<Ship>(null);
    public selectedShipObservable = this.selectedShip.pipe(this.fnFilterNotNull);
    public aShips = new BehaviorSubject<Ship[]>(null);
    public aShipsObservable = this.aShips.pipe(this.fnFilterNotNull);
    public sToken = new BehaviorSubject<string>(null);
    public sTokenObservable = this.sToken.pipe(this.fnFilterNotNull);

    constructor() {
    }


    emit(data : EventOnBus) {
        this.objectToEmit.next(data);
    }

}
