import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Cruise } from '../classes/Cruise';
import { Observable } from 'rxjs';
import { PersistenceHelperService } from '../util/persistence-helper.service';
import { ComponentConnectorService } from './component-connector.service';


@Injectable({
  providedIn: 'root'
})
export class CruiseService {

  // localhost
	// private sUrlPrefix = "/php/statekopole/";
	//productive
	private sUrlPrefix = "app/php/";

	private getCruiseUrl = this.sUrlPrefix + "getCruises.php";
	private addCruiseUrl = this.sUrlPrefix + "addCruise.php";
	private updateCruiseUrl = this.sUrlPrefix + "updateCruise.php";
	private deleteCruiseUrl = this.sUrlPrefix + "deleteCruise.php";
	

    constructor(private http: HttpClient, 
        private PersistenceHelper: PersistenceHelperService,
        private componentConnectorService : ComponentConnectorService) { }

	getCruises(oFilter? : object) : Observable<Cruise[]> {

		let sToken = this.componentConnectorService.sToken.getValue();
        
        let oParams = {
            token: sToken
        };

        if(oFilter != null) {
            for(var sKey in oFilter) {
                oParams[sKey] = oFilter[sKey];
            }
        }

        return this.http.get<Cruise[]>(this.getCruiseUrl, {
            params: oParams
        });

	}

	addCruise(cruise: Cruise) : Observable<Cruise> {

		let sToken = this.componentConnectorService.sToken.getValue();

		var oHeaders = new HttpHeaders();

		oHeaders = oHeaders.append("x-session-token", sToken);

		return this.http.post<Cruise>(this.addCruiseUrl, cruise, {
			headers: oHeaders
		});
	}

	deleteCruises(cruises: Cruise[]) : Observable<{}> {

        let sToken = this.componentConnectorService.sToken.getValue();

		var oHeaders = new HttpHeaders();

		oHeaders = oHeaders.append("x-session-token", sToken);

		let aId = [];

		for(let i in cruises) {
			aId.push(cruises[i].id);
		}

		return this.http.post(this.deleteCruiseUrl, {
			id: aId
		}, {
            headers: oHeaders
        });
	}

	updateCruise(cruise: Cruise) : Observable<Cruise> {

        let sToken = this.componentConnectorService.sToken.getValue();

		var oHeaders = new HttpHeaders();

		oHeaders = oHeaders.append("x-session-token", sToken);

		return this.http.post<Cruise>(this.updateCruiseUrl, cruise, {
            headers: oHeaders
        });
	}

}
