import { Injectable } from '@angular/core';

@Injectable({
  	providedIn: 'root'
})
export class PersistenceHelperService {

	constructor() { };

	getQueryObject() : any {

        let sQuery = location.search;
        
        if(sQuery.length === 0) {
            return {};
        }

		if(sQuery != null && sQuery.length > 0 && sQuery[0] === "?") {
			sQuery = sQuery.slice(1);
		}

		var vars = sQuery.split("&");
		var query_string = {};

		for (var i = 0; i < vars.length; i++) {
			var pair = vars[i].split("=");
			var key = decodeURIComponent(pair[0]);
			var value = decodeURIComponent(pair[1]);
			// If first entry with this name
			if (typeof query_string[key] === "undefined") {
			query_string[key] = decodeURIComponent(value);
			// If second entry with this name
			} else if (typeof query_string[key] === "string") {
			var arr = [query_string[key], decodeURIComponent(value)];
			query_string[key] = arr;
			// If third or later entry with this name
			} else {
			query_string[key].push(decodeURIComponent(value));
			}
		}

		return query_string;

    };
    
    createUrl(oParams : Object) {

        var sUrl = "?";

        for(var sKey in oParams) {
            if(sUrl.length > 1) {
                sUrl += "&";
            }
            sUrl += sKey + "=" + oParams[sKey];
        }

        return sUrl;

    }

    setCookie(name,value,days) {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days*24*60*60*1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "")  + expires + "; path=/";
    }
    
    getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for(var i=0;i < ca.length;i++) {
            var c = ca[i];
            while (c.charAt(0)==' ') c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
        }
        return null;
    }
    
    eraseCookie(name) {   
        document.cookie = name+'=; Max-Age=-99999999;';  
    }

}
