<form [formGroup]="form" class="form" (keydown.enter)="handleLogin()">
	<mat-form-field 
		style="display: none;">
		<input
			hidden
			matInput
			formControlName="username" 
			type="text"
			autocomplete="username"
			placeholder="Podaj nazwę użytkownika">
    </mat-form-field>
  	<mat-form-field>
      <input 
          matInput
          formControlName="password" 
          type="password"
          autocomplete="current-password"
          placeholder="Podaj hasło">
  	</mat-form-field>
</form>
<div>
  	<button mat-raised-button (click)="handleLogin()">Zaloguj</button>
</div>