import { Component, OnInit } from '@angular/core';
import { ComponentConnectorService } from '../../service/component-connector.service';
import { EventOnBus } from '../../classes/EventOnBus';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Ship } from '../../classes/Ship';

@Component({
  selector: 'app-header-ship',
  templateUrl: './header-ship.component.html',
  styleUrls: ['./header-ship.component.css']
})
export class HeaderShipComponent implements OnInit {

	public selectedShip : Ship;

  	constructor(private componentConnectorService: ComponentConnectorService) { }

  	ngOnInit() {

        this.componentConnectorService.selectedShipObservable.subscribe({
            next: this.shipSelected.bind(this)
        })

    }

    shipSelected(oShip : Ship) {

        this.selectedShip = oShip;

    }
      
    onChangeShip() {

        this.componentConnectorService.emit(new EventOnBus("selectShip"));

    }

}
