import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { LoginService } from '../service/login.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ComponentConnectorService } from '../service/component-connector.service';
import { EventOnBus } from '../classes/EventOnBus';
import { PersistenceHelperService } from '../util/persistence-helper.service';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.css']
})
export class LoginPageComponent implements OnInit {

  public form = new FormGroup({
        username : new FormControl(""),
		password : new FormControl("")
	});

    constructor(private loginService : LoginService,
                private componentConnectorService: ComponentConnectorService,
                public snackBar: MatSnackBar, 
                private PersistenceHelper: PersistenceHelperService) { }

    ngOnInit() {

        this.handleLoginToken();

    }

    handleLogin() {
        let sPassword = this.form.get("password").value;
        this.loginService.handleLogin(sPassword).subscribe(function(sResponse) {
            this.handleLoginResponse(sResponse);
        }.bind(this));
    }

    handleLoginToken() {

        let sToken = this.PersistenceHelper.getCookie("sessionId");

		if(sToken != null && sToken !== "") {

            this.loginService.handleLoginToken(sToken).subscribe(function(sResponse) {
                this.handleLoginResponse(sResponse);
            }.bind(this));

        }

    }

    handleLoginResponse(oResponse) {

        if(oResponse && oResponse.logged === true) {

            let oTomorrow = new Date();

            oTomorrow.setDate(oTomorrow.getDate() + 1);

            // set cookie
            this.PersistenceHelper.setCookie("sessionId", oResponse.token, 7);

            this.componentConnectorService.sToken.next(oResponse.token);
            this.componentConnectorService.emit(new EventOnBus("login"));
            
        } else if(oResponse && oResponse.logged === false) {
            this.snackBar.open("Błędne hasło", null, {
				duration: 2000
			});
        } else if(oResponse === false) {
            this.snackBar.open("Błąd serwera", null, {
				duration: 2000
			});
        }

    }

}
