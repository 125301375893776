import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


@Injectable({
  	providedIn: 'root'
})
export class LoginService {

	// localhost
	// private sUrlPrefix = "/php/statekopole/";
	//productive
	private sUrlPrefix = "app/php/";

	private loginUrl = this.sUrlPrefix + "handleLogin.php";

	constructor(private http: HttpClient) { }

  	handleLogin(sPassword : string) {
		return this.http.get<string>(this.loginUrl, {
			params: {password: sPassword}
		});
	}

	handleLoginToken(sToken : string) {

		return this.http.get<string>(this.loginUrl, {
			params: {token: sToken}
		});

	}
  
}
