import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Ship } from '../../../classes/Ship';
import { ComponentConnectorService } from '../../../service/component-connector.service';

@Component({
	selector: 'app-ships-dialog',
	templateUrl: './ships-dialog.component.html',
	styleUrls: ['./ships-dialog.component.css']
})
export class ShipsDialogComponent{

  	constructor(
		public dialogRef: MatDialogRef<ShipsDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: Ship[],
		private componentConnectorService: ComponentConnectorService) {}

	onShipSelect(oShip: Ship) {

		this.dialogRef.close();

		this.componentConnectorService.selectedShip.next(oShip);

	}

}
